import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Head from "../components/head"
import Header from "../components/header"
import Footer from "../components/footer"
import { LoginHeader } from "../components/nav"
import { SocialHeader } from "../components/social"

export function Banner_img() {
  return <StaticImage src="../../static/images/banner.jpg" alt="banner" />
}
export default () => (
  <>
    <Header titleText="Home - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <LoginHeader />
        <SocialHeader />
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="images/happy-mango-lockup-color.svg"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
        <div className="clear_both"></div>
      </div>
      <Head />
      <div className="banner">
        <Banner_img />
        <div className="banner_text">
          <div className="bannerinfo">
            FINANCIAL TECHNOLOGY <br />
            SERVING THE UNDERSERVED
          </div>
          <ul className="loginbox">
            <li>
              <a
                rel=""
                href="https://calendly.com/kate-hao_happy-mango/happy-mango-30-minute-online-demo"
                className="banks-get-a-demo"
              >
                Demo
              </a>
            </li>
            <li>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
                className="banks-try-for-free"
              >
                Trial
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="media_list media_list_index">
        <ul>
          <li>
            <a rel="" href="/media/">
              <img
                alt="americanbanker"
                src="/sites/default/files/americanbanker1.jpg"
              />{" "}
            </a>
          </li>
          <li>
            <a rel="" href="/media/">
              <img alt="usatoday" src="/sites/default/files/usatoday.jpg" />{" "}
            </a>
          </li>
          <li>
            <a rel="" href="/media/">
              <img
                alt="credit-union-times"
                src="/sites/default/files/credit-union-times1.jpg"
              />{" "}
            </a>
          </li>
        </ul>
      </div>

      <footer className="footer_position">
        <Footer />
      </footer>
    </div>
  </>
)
